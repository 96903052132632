// Entry point for the build script in your package.json
global.jQuery = global.$ = require("jquery")
import "jquery-ui-dist/jquery-ui.js"
require("jquery-minicolors")

import Rails from "@rails/ujs"
global.Rails = Rails
import "./channels"

require("./application/theme.js")
import * as bootstrap from 'bootstrap'

require("chartkick/chart.js")
//import Chart from 'chart.js';
//import "chartkick/chart.js"
import { chartkickTooltipHelper } from "./application/chartkick.js"
global.chartkickTooltipHelper = chartkickTooltipHelper;

import {decode} from "base64-arraybuffer"
global.base64decode = decode;

global.colors = require("./application/colors.js")
require("./application/table-select.js")
require("./application/bootstrap-tokenfield.js")
require("./application/bootstrap-colorpicker.js")
require("./application/freezeheader.js")
require("./application/jquery.magnific-popup.js")

import { bindLogoBackgroundPreview, bindThumbnailBgAutoLoaded } from "./application/logo_background_preview.js"
global.bindLogoBackgroundPreview = bindLogoBackgroundPreview;
global.bindThumbnailBgAutoLoaded = bindThumbnailBgAutoLoaded;

import { formatBytes, formatBits, set_deep_value, remove_fields, add_fields, append_fields, send_search, quick_search_item_mouseover, reset_registration_key_result, price_category_show_final_price, init_search_autocomplete } from "./application/application.js"
global.formatBytes = formatBytes;
global.formatBits = formatBits;
global.set_deep_value = set_deep_value;
global.remove_fields = remove_fields;
global.add_fields = add_fields;
global.append_fields = append_fields;
global.send_search = send_search;
global.quick_search_item_mouseover = quick_search_item_mouseover;
global.reset_registration_key_result = reset_registration_key_result;
global.price_category_show_final_price = price_category_show_final_price;
global.init_search_autocomplete = init_search_autocomplete;

import { utilization_init as device_utilization_init, utilization_connected as device_utilization_connected, utilization_process as device_utilization_process, utilization_live_channel_event as device_utilization_live_channel_event } from "./application/device_control_utilization.js"
global.device_utilization_init = device_utilization_init;
global.device_utilization_connected = device_utilization_connected;
global.device_utilization_process = device_utilization_process;
global.device_utilization_live_channel_event = device_utilization_live_channel_event;

import {
  log_init as device_log_init, log_connected as device_log_connected, log_disconnected as device_log_disconnected, log_live_channel_event as device_log_live_channel_event, log_live as device_log_live, log_tail as device_log_tail,
  log_history_init as device_log_history_init, log_history_connected as device_log_history_connected, log_history_disconnected as device_log_history_disconnected, log_history as device_log_history
} from "./application/device_control_log.js"
global.device_log_init = device_log_init;
global.device_log_connected = device_log_connected;
global.device_log_disconnected = device_log_disconnected;
global.device_log_live_channel_event = device_log_live_channel_event;
global.device_log_live = device_log_live;
global.device_log_tail = device_log_tail;
global.device_log_history_init = device_log_history_init;
global.device_log_history_connected = device_log_history_connected;
global.device_log_history_disconnected = device_log_history_disconnected;
global.device_log_history = device_log_history;


Rails.start();

//console.log("app")

$(document).ready(function() {
  //console.log("csrf setup")
  $.ajaxSetup({
    headers: {
      'X-CSRF-Token': Rails.csrfToken()
    }
  });
});

//Tooltips are opt-in for performance reasons, so you must initialize them yourself.
/*$(function() {
  $('[data-bs-toggle="tooltip"]').tooltip();
});*/
//Popovers are opt-in for performance reasons, so you must initialize them yourself.
/*$(function() {
  $('[data-bs-toggle="popover"]').popover();
});*/
