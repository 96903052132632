import { set_deep_value } from "./application.js"

var utilization_autoload = true;
var utilization_net_autoenabled = [];
export function utilization_init(autoload, net_autoenabled) {
  utilization_autoload = autoload;
  utilization_net_autoenabled = net_autoenabled;
  utilization_improve_charts_settings();
  utilization_load();
}

//charts settings
function relativeTimeTitle(tooltipItems) {
  if(tooltipItems.length > 0) {
    var secs = Math.round(-tooltipItems[0].parsed.x);
    var minutes = Math.floor(secs/60);
    secs -= minutes*60;
    return minutes + ":" + (secs < 10 ? "0" : "") + secs + " ago";
  }
  return '';
}
function binaryScale(number, decimalPlaces) {
  var d = Math.pow(10, decimalPlaces);
  if(number >= 1073741824) return Math.round((number/1073741824)*d)/d + " G";
  if(number >= 1048576) return Math.round((number/1048576)*d)/d + " M";
  if(number >= 1024) return Math.round((number/1024)*d)/d + " k";
  return number + " ";
}
function binaryScaledLessThan10(number) {
  if(number >= 1073741824) return Math.round(number/1073741824) < 10;
  if(number >= 1048576) return Math.round(number/1048576) < 10;
  if(number >= 1024) return Math.round(number/1024) < 10;
  return number < 10;
}
function bytesScale(val, index) { return binaryScale(val, 1) + "B"; }
function bytesLabel(tooltipItem) { return tooltipItem.dataset.label + ': ' + binaryScale(tooltipItem.parsed.y, 3) + "B"; }
function bpsScale(val, index) { return binaryScale(val, binaryScaledLessThan10(val) ? 1 : 0) + "bps"; }
function bpsLabel(tooltipItem) { return tooltipItem.dataset.label + ': ' + binaryScale(tooltipItem.parsed.y, 3) + "bps"; }
function utilization_improve_charts_settings() {
  var cpu_chart = Chartkick.charts["device-control-cpu"];
  var memory_chart = Chartkick.charts["device-control-memory"];
  var network_chart = Chartkick.charts["device-control-network"];
  var options;
  options = cpu_chart.options;     set_deep_value(options, "library.plugins.tooltip.callbacks.title", relativeTimeTitle); cpu_chart.setOptions(options);
  options = memory_chart.options;  set_deep_value(options, "library.plugins.tooltip.callbacks.title", relativeTimeTitle); set_deep_value(options, "library.plugins.tooltip.callbacks.label", bytesLabel); set_deep_value(options, "library.scales.y.ticks.callback", bytesScale); memory_chart.setOptions(options);
  options = network_chart.options; set_deep_value(options, "library.plugins.tooltip.callbacks.title", relativeTimeTitle); set_deep_value(options, "library.plugins.tooltip.callbacks.label", bpsLabel); set_deep_value(options, "library.scales.y.ticks.callback", bpsScale); network_chart.setOptions(options);
}

//utilization settings
var utilization_show = false; //1 history, 2 live
var utilization_cpu_cores = false;
var utilization_net_checks = {};
//utilization controls
var dcu_hour, dcu_minute, dcu_cpu_sum, dcu_cpu_cores, dcu_net;
function utilization_load() {
  dcu_hour = document.getElementById("dcu_hour");
  dcu_minute = document.getElementById("dcu_minute");
  dcu_cpu_sum = document.getElementById("dcu_cpu_sum");
  dcu_cpu_cores = document.getElementById("dcu_cpu_cores");
  dcu_net = document.getElementById("dcu_net");
  dcu_hour.addEventListener("click", function(e) { e.preventDefault(); utilization_show = 1; utilization_ask_data(); utilization_refresh_with_controls(); });
  dcu_minute.addEventListener("click", function(e) { e.preventDefault(); utilization_show = 2; utilization_ask_data(); utilization_refresh_with_controls(); });
  dcu_cpu_sum.addEventListener("click", function(e) { e.preventDefault(); utilization_cpu_cores = false; utilization_refresh_with_controls(); });
  dcu_cpu_cores.addEventListener("click", function(e) { e.preventDefault(); utilization_cpu_cores = true; utilization_refresh_with_controls(); });
  utilization_refresh_controls();
}
function utilization_add_network_checkbox(intf) {
  if(!dcu_net.querySelector("input")) dcu_net.innerHTML = "";
  var label = document.createElement("label");
  label.className = "fw-normal me-2 mb-1";
  label.innerHTML = '<input type="checkbox" '+ (utilization_net_autoenabled.includes(intf) ? 'checked="checked"' : '') +' /> '+intf;
  dcu_net.appendChild(label);
  var checkbox = label.querySelector("input");
  checkbox.addEventListener("change", utilization_refresh);
  utilization_net_checks[intf] = checkbox;
}
function utilization_refresh_controls() {
  if(utilization_show) document.getElementById("device-control-charts").classList.remove("visually-hidden");
  if(utilization_show == 1) dcu_hour.classList.add("active"); else dcu_hour.classList.remove("active");
  if(utilization_show == 2) dcu_minute.classList.add("active"); else dcu_minute.classList.remove("active");
  if(utilization_cpu_cores == false) dcu_cpu_sum.classList.add("active"); else dcu_cpu_sum.classList.remove("active");
  if(utilization_cpu_cores == true) dcu_cpu_cores.classList.add("active"); else dcu_cpu_cores.classList.remove("active");
}
function utilization_refresh_with_controls() {
  utilization_refresh_controls();
  utilization_refresh();
}
export function utilization_connected(was_disconnected) {
  if(!was_disconnected) { // first run
    if(utilization_autoload) {
      utilization_show = 1;
      utilization_refresh_controls();
    } else {
      if(device_control_connected) device_control_subscription.perform('utilization', {util_command: 'available'});
    }
    utilization_ask_data();
    setInterval(utilization_ask_data, 5000);
  }
}
export function utilization_live_channel_event(message) {
  if(message.event == "subscribed") {
    if(device_control_connected) device_control_subscription.perform('utilization', {util_command: 'available'});
  }
}
var utilization_history_received = 0;
function utilization_ask_data() {
  if(utilization_show == 1) {
    if(utilization_history_received > new Date().getTime() - 61000) return;
    if(device_control_connected) device_control_subscription.perform('utilization', {util_command: 'history'});
  } else if(utilization_show == 2) {
    if(device_control_connected) device_control_subscription.perform('utilization', {util_command: 'report'});
  }
}

// utilization data display
var utilization_history_data = [];
var utilization_live_data = [];
var utilization_cpu_keys = [], utilization_network_keys = [];
function utilization_register_keys(sample) {
  Object.keys(sample.cpu).forEach(function (key) {
    if(utilization_cpu_keys.indexOf(key) < 0) utilization_cpu_keys.push(key);
  });
  Object.keys(sample.network).forEach(function (key) {
    if(utilization_network_keys.indexOf(key) < 0) {
      utilization_network_keys.push(key);
      utilization_add_network_checkbox(key);
    }
  });
}
export function utilization_process(data) {
  //console.log(data);
  //{"action":"utilization","sample":{"cpu":{"0":12},"memory":{"mem_total":17179869184,"mem_used":7139082240,"swap_total":1073741824,"swap_used":233635840},"network":{"ap1":{"in_bps":0,"out_bps":0},"awdl0":{"in_bps":0,"out_bps":0},"en0":{"in_bps":8192,"out_bps":8192},"lo0":{"in_bps":40960,"out_bps":40960},"utun3":{"in_bps":0,"out_bps":0}},"time":2234599545908}
  document.getElementById('device_utilization_button').style.display = '';
  if(data.available) return; //it just shows the button
  if(data.history) {
    utilization_history_received = new Date().getTime();
    utilization_history_data = data.history;
    for(var i = 0; i<utilization_history_data.length; i++) utilization_register_keys(utilization_history_data[i]);
  } else if(data.sample) {
    if(data.sample.time < ((utilization_live_data[utilization_live_data.length-1] || {}).time || 0)) return;
    utilization_live_data.push(data.sample);
    utilization_register_keys(data.sample);
    while(utilization_live_data.length > 60) utilization_live_data.shift();
  }
  utilization_refresh();
}
function utilization_refresh() {
  if(!utilization_show) return;
  var cpu_chart = Chartkick.charts["device-control-cpu"];
  var memory_chart = Chartkick.charts["device-control-memory"];
  var network_chart = Chartkick.charts["device-control-network"];
  var options;
  if(utilization_show == 1) {
    options = cpu_chart.options;     options.xmin = -3540; cpu_chart.setOptions(options);
    options = memory_chart.options;  options.xmin = -3540; memory_chart.setOptions(options);
    options = network_chart.options; options.xmin = -3540; network_chart.setOptions(options);
  } else {
    options = cpu_chart.options;     options.xmin = -60; options.messages || (options.messages = {empty: "Waiting for data"}); cpu_chart.setOptions(options);
    options = memory_chart.options;  options.xmin = -60; options.messages || (options.messages = {empty: "Waiting for data"}); memory_chart.setOptions(options);
    options = network_chart.options; options.xmin = -60; options.messages || (options.messages = {empty: "Waiting for data"}); network_chart.setOptions(options);
  }
  if(utilization_cpu_cores) {
    options = cpu_chart.options;     options.max = 100; cpu_chart.setOptions(options);
  } else {
    options = cpu_chart.options;     options.max = 100/* *utilization_cpu_keys.length*/; cpu_chart.setOptions(options);
  }

  var utilization_data = utilization_show == 1 ? utilization_history_data : utilization_live_data;
  var last_time = (utilization_data[utilization_data.length-1] || {}).time;
  var cpu = {}, network_in = {}, network_out = {};
  if(utilization_cpu_cores) utilization_cpu_keys.forEach(function(key) { cpu[key] = {name: "CPU"+key, data: []}; });
  else cpu["sum"] = {name: "CPU", data: []};
  utilization_network_keys.forEach(function(key) { network_in[key] = {name: key+" received", data: []}; network_out[key] = {name: key+" transmitted", data: []}; });
  var memory_data = [{name: "Memory Available", data: [], color: "#109618", dataset: {fill: true, backgroundColor: "#10961810"}}, {name: "Memory Used", data: [], color: "#3366CC"}, {name: "Swap Size", data: [], color: "#FF9900", dataset: {fill: true, backgroundColor: "#FF990060"}}, {name: "Swap Used", data: [], color: "#DC3912"}];
  var memory_max = 1024;
  for(var i = 0; i<utilization_data.length; i++) {
    var sample = utilization_data[i];
    var dtime = (sample.time - last_time)/1000000;
    //cpu
    var sum = 0;
    for(var j = 0; j<utilization_cpu_keys.length; j++) {
      var key = utilization_cpu_keys[j];
      if(utilization_cpu_cores) cpu[key].data.push([dtime, sample.cpu[key] || 0]);
      else sum += sample.cpu[key] || 0;
    }
    sum /= utilization_cpu_keys.length;/**/
    if(!utilization_cpu_cores) cpu["sum"].data.push([dtime, sum]);
    //memory
    memory_data[0].data.push([dtime, sample.memory.mem_total]);
    memory_data[1].data.push([dtime, sample.memory.mem_used]);
    memory_data[2].data.push([dtime, sample.memory.swap_total]);
    memory_data[3].data.push([dtime, sample.memory.swap_used]);
    if(sample.memory.mem_total > memory_max) memory_max = sample.memory.mem_total;
    if(sample.memory.swap_total > memory_max) memory_max = sample.memory.swap_total;
    //net
    for(var j = 0; j<utilization_network_keys.length; j++) {
      var key = utilization_network_keys[j];
      if(!utilization_net_checks[key].checked) continue;
      network_in[key].data.push([dtime, (sample.network[key] || {}).in_bps || 0]);
      network_out[key].data.push([dtime, (sample.network[key] || {}).out_bps || 0]);
    }
  }
  var cpu_data = [], network_data = [];
  if(utilization_cpu_cores) utilization_cpu_keys.forEach(function(key) { cpu_data.push(cpu[key]); });
  else cpu_data.push(cpu["sum"]);
  utilization_network_keys.forEach(function(key) { network_data.push(network_in[key]); network_data.push(network_out[key]); });

  cpu_chart.updateData(cpu_data);
  options = memory_chart.options; options.max = memory_max; memory_chart.setOptions(options);
  memory_chart.updateData(memory_data);
  network_chart.updateData(network_data);
}
